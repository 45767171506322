import { Button, Menu, MenuItem } from '@mui/material';
import * as React from 'react';
import { Loadable, useRecoilState, useRecoilValueLoadable } from 'recoil';

import { BlamAction } from '../models';
import { AssetService } from '../services';
import {
  actionsState,
  globalFormModalDetails,
  globalFormModalState,
} from '../state';
import { convertAction } from '../utils/action';

export function GlobalActions() {
  const assetService = new AssetService();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const remoteActions: Loadable<BlamAction[]> =
    useRecoilValueLoadable(actionsState);
  const [_2, setGlobalActionOpen] = useRecoilState(globalFormModalState);
  const [_3, setGlobalActionDetails] = useRecoilState(globalFormModalDetails);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const actions = [
    {
      label: 'Create Collection',
      action: () => {
        setGlobalActionDetails({
          title: 'Create Collection',
          onSubmit: async params => {
            await assetService.createCollection(params['name']);
          },
          parameters: [
            {
              key: 'name',
              name: 'Name',
              required: true,
              type: 'string',
            },
          ],
        });
        setGlobalActionOpen(true);
      },
    },
  ];
  if (remoteActions.state === 'hasValue') {
    actions.push(
      ...remoteActions.contents
        .filter(a => !a.requiresAsset)
        .map(
          action =>
            convertAction(action, setGlobalActionDetails, () =>
              setGlobalActionOpen(true),
            ) as { label: string; action: () => void },
        ),
    );
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Actions
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {actions.map((action, idx) => (
          <MenuItem
            key={idx}
            onClick={() => {
              action.action();
              handleClose();
            }}
          >
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
