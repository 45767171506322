import { FormControl, MenuItem, TextField } from '@mui/material';
import * as React from 'react';

import { GenericFieldProps } from '../../models';

export function PicklistField<T>(props: GenericFieldProps) {
  let options = [];
  const optionLabelKey = props.field.picklistConfig?.optionLabelKey || 'label';
  const optionValueKey = props.field.picklistConfig?.optionValueKey || 'value';
  if (!props.field.picklistConfig) {
    throw Error('PicklistField requires a picklistConfig');
  } else if (props.field.picklistConfig.optionsLoadable) {
    options =
      props.field.picklistConfig.optionsLoadable.state === 'hasValue'
        ? props.field.picklistConfig.optionsLoadable.contents
        : [];
  } else {
    options = props.field.picklistConfig.options || [];
  }
  options = options.map((option, idx) => (
    <MenuItem key={idx} value={option[optionValueKey]}>
      {option[optionLabelKey]}
    </MenuItem>
  ));
  return (
    <FormControl style={{ minWidth: 300 }}>
      <TextField
        id={props.field.key}
        select
        label={props.field.name}
        onChange={event => props.onChange(event)}
      >
        {options}
      </TextField>
    </FormControl>
  );
}
