import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import { AssetLocation } from '../../models';

export function AssetLocationAccordian(props: {
  title: string;
  metadata: { [key: string]: string };
  location: AssetLocation;
  onClickCallback: () => void;
}) {
  return (
    <div>
      <Accordion onClick={props.onClickCallback}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{props.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell width="150px">Path</TableCell>
                  <TableCell width="250px">{props.location.path}</TableCell>
                </TableRow>
                {Object.keys(props.metadata).map(key => (
                  <TableRow key={key}>
                    <TableCell width="150px">{key}</TableCell>
                    <TableCell width="250px">{props.metadata[key]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
