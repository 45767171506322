import { AWS_CONFIG } from '../gen/aws';
import { ApiService } from '../utils';

interface UserPreferences {
  currentView?: string;
}

const apiConfig = AWS_CONFIG.api.gateways.misc;

export class UserPreferenceService extends ApiService {
  constructor() {
    super(apiConfig.name, `${apiConfig.path}/preferences`);
  }

  public async getPreferences(): Promise<UserPreferences> {
    return this.apiGet();
  }

  public async updatePreferences(
    preferences: UserPreferences,
  ): Promise<UserPreferences> {
    return this.apiPut('', preferences);
  }
}
