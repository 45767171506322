/* eslint-disable require-jsdoc */
import { AWS_CONFIG } from '../gen/aws';
import { StorageAccount } from '../models';
import { ConnectedAccount } from '../models/connected-accounts';
import { ApiService } from '../utils';

interface GphotoRes {
  pageToken?: string;
}

const apiConfig = AWS_CONFIG.api.gateways.connector;

export class AccountService extends ApiService {
  constructor() {
    super(apiConfig.name, apiConfig.path);
  }

  public async authorizeGoogle(): Promise<void> {
    const url = await this.getGoogleAuthUrl();
    window.open(url);
  }

  private async getGoogleAuthUrl(): Promise<string> {
    return (await this.apiGet<{ url: string }>('google/auth')).url;
  }

  public async addGooglePhotos(pageToken?: string): Promise<GphotoRes> {
    return await this.apiPost<GphotoRes, GphotoRes>(
      'gphotos/add',
      pageToken ? { pageToken: pageToken } : {},
    );
  }

  public async getConnectedAccounts(): Promise<ConnectedAccount[]> {
    return await this.apiGet<ConnectedAccount[]>();
  }

  public async createConnectedAccount(
    name: string,
    accountType: string,
  ): Promise<ConnectedAccount> {
    return await this.apiPost<any, ConnectedAccount>('create', {
      name,
      accountType,
    });
  }
}
