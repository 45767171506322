/* eslint-disable require-jsdoc */
import { AWS_CONFIG } from '../gen/aws';
import { Asset, AssetCollection, AssetLocation, AssetStore } from '../models';
import { ApiService } from '../utils';

interface DownloadUrlRes {
  downloadUrl: string;
  assetId: string;
}

const apiConfig = AWS_CONFIG.api.gateways.assets;

export class AssetService extends ApiService {
  private collectionPath = 'collection';

  constructor() {
    super(apiConfig.name, apiConfig.path);
  }

  public async createAsset(
    name: string,
    s3Path: string,
    assetType: string,
  ): Promise<Asset> {
    return this.apiPost('', {
      name: name,
      location: { path: s3Path, use: 'original' },
      assetType: assetType,
    });
  }

  public async getAssets(query?: string): Promise<AssetStore[]> {
    return this.apiGet('', { query: query ? query : '' });
  }

  public async getAsset(
    id: string,
  ): Promise<{ asset: AssetStore; locations: AssetLocation[] }> {
    return this.apiGet<{ asset: AssetStore; locations: AssetLocation[] }>(
      `detailed/${id}`,
    );
  }

  public async getAssetUrl(id: string, use = 'proxy'): Promise<DownloadUrlRes> {
    try {
      return this.apiGet<DownloadUrlRes>(`download/${id}?use=${use}`);
    } catch (e) {
      return this.apiGet<DownloadUrlRes>(`download/${id}`);
    }
  }

  public async addLocationToAsset(
    id: string,
    assetLocation: { path: string; use: string; location: string },
  ): Promise<{ id: string }> {
    return this.apiPost<any, { id: string }>(`${id}/locations`, assetLocation);
  }

  public async updateAssetMetadata(id: string, metadata: any): Promise<Asset> {
    return this.apiPut(`metadata/${id}`, { metadata: metadata });
  }

  public async createCollection(name: string): Promise<AssetCollection> {
    return this.apiPost(this.collectionPath, { name: name });
  }

  public async getCollections(): Promise<AssetCollection[]> {
    return this.apiGet<AssetCollection[]>(this.collectionPath);
  }

  public async getCollectionAssets(
    collectionId: string,
  ): Promise<AssetStore[]> {
    return this.apiGet<AssetStore[]>(`${this.collectionPath}/${collectionId}`);
  }

  public async addAssetsToCollection(
    assetIds: string[],
    collectionId: string,
  ): Promise<AssetCollection> {
    return this.apiPut(`${this.collectionPath}/${collectionId}/add`, assetIds);
  }

  public async removeAssetsFromCollection(
    assetIds: string[],
    collectionId: string,
  ): Promise<AssetCollection> {
    return this.apiPut(
      `${this.collectionPath}/${collectionId}/remove`,
      assetIds,
    );
  }
}
