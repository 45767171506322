import { atom, atomFamily } from 'recoil';

import { UserPreferenceService } from '../services';
import { runWithRetries } from '../utils';

export const tileHoverState = atomFamily<boolean, string>({
  key: 'tileHoverState',
  default: false,
});

export const asseViewState = atom({
  key: 'assetPageState',
  default: new Promise<string>(resolve => {
    const prefService = new UserPreferenceService();
    runWithRetries(() => prefService.getPreferences(), 3, 250)
      .then(res => resolve(res.currentView ? res.currentView : 'list'))
      .catch(err => resolve('list'));
  }),
});

export const searchBoxState = atom({
  key: 'searchBoxState',
  default: false,
});
