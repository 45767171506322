/* eslint-disable require-jsdoc */
import { AWS_CONFIG } from '../gen/aws';
import { MetadataField } from '../models';
import { ApiService } from '../utils';

const apiConfig = AWS_CONFIG.api.gateways.metadata;

export class MetadataService extends ApiService {
  constructor() {
    super(apiConfig.name, apiConfig.path);
  }

  public async getFields(): Promise<MetadataField[]> {
    return this.apiGet();
  }
}
