/* eslint-disable require-jsdoc */
import { API } from 'aws-amplify';

import { deserialize, serialize } from './model';

export class ApiService {
  constructor(private apiName: string, private path: string = '') {}

  protected async apiGet<T>(
    path = '',
    queryStringParameters?: { [key: string]: string },
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      const extraParams = queryStringParameters
        ? { queryStringParameters }
        : {};
      API.get(this.apiName, `${this.path}/${path}`, extraParams)
        .then(res => resolve(deserialize(res)))
        .catch(reject);
    });
  }

  protected async apiPost<T, U>(path: string, body: T): Promise<U> {
    return new Promise((resolve, reject) => {
      API.post(this.apiName, `${this.path}/${path}`, { body: serialize(body) })
        .then(res => resolve(deserialize(res)))
        .catch(reject);
    });
  }

  protected async apiPut<T, U>(path: string, body: T): Promise<U> {
    return new Promise((resolve, reject) => {
      API.put(this.apiName, `${this.path}/${path}`, { body: serialize(body) })
        .then(res => resolve(deserialize(res)))
        .catch(reject);
    });
  }
}
