import { AssetStore, BlamAction, FormConfig } from '../models';
import { ActionService } from '../services';

export const convertAction = (
  action: BlamAction,
  setModalDetails: (formConfig: FormConfig) => void,
  openFn: () => void,
): { label: string; action: (asset: AssetStore) => void | (() => void) } => {
  const value = {
    label: action.name,
    action: (asset?: AssetStore) => {
      setModalDetails({
        title: action.name,
        onSubmit: async params => {
          const actionService = new ActionService();
          const assetUse = params.use || 'original';
          delete params.assetUse;
          await actionService.startAction(
            action.id,
            asset ? asset.id : undefined,
            params,
            assetUse,
          );
        },
        parameters: [
          ...Object.keys(action.parameters).map(key => {
            action.parameters[key].key = key;
            return action.parameters[key];
          }),
          {
            key: 'use',
            name: 'Use',
            type: 'string',
            default: 'original',
          },
        ],
      });
      openFn();
    },
  };
  return value;
};
