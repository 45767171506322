import CropIcon from '@mui/icons-material/Crop';
import { Grid, IconButton } from '@mui/material';
import * as React from 'react';
import ReactCrop, { Crop, PercentCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useParams } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';

import { FormFieldConfig, parseMetadataAndLocations } from '../../models';
import { ActionService } from '../../services';
import { assetState, downloadUrlStateFamily } from '../../state';
import { canvasPreview } from '../../utils';
import { PicklistField } from '../forms/PicklistField';

export function ImageEdit() {
  const actionService = new ActionService();
  const { id } = useParams();
  const asset = useRecoilValueLoadable(assetState(id || ''));
  const [editValue, setEditValues] = React.useState({
    use: 'original',
    cropped: false,
  });
  const [crop, setCrop] = React.useState<Crop>();
  const urlLoadable = useRecoilValueLoadable(
    downloadUrlStateFamily({ id: id || '', use: editValue.use || 'original' }),
  );
  if (asset.state != 'hasValue' || !asset.contents) {
    return <div>Loading...</div>;
  }
  const mdByLocation = parseMetadataAndLocations(
    asset.contents.locations,
    asset.contents.metadata,
  );
  const useListConfig: FormFieldConfig = {
    picklistConfig: {
      options: Object.keys(mdByLocation).map(key => ({
        label: key,
        value: key,
      })),
    },
    type: 'picklist',
    key: 'use',
    name: 'Use to edit',
  };
  const onUseChange = (data: { value: string }) =>
    setEditValues({ cropped: false, use: data.value });
  const onCropChange = (crop: Crop) => {
    setCrop(crop);
  };
  const imgRef = React.useRef<HTMLImageElement>(null);
  const previewCanvasRef = React.useRef<HTMLCanvasElement>(null);
  const onSubmitCrop = () => {
    if (imgRef.current && crop && !editValue.cropped) {
      canvasPreview(
        imgRef.current,
        previewCanvasRef.current as any,
        crop as any,
        1,
        0,
      );
      setEditValues({ ...editValue, cropped: true });
      actionService.startAction('cropImage', id || '', crop).catch(console.log);
    } else if (editValue.cropped) {
      setEditValues({ ...editValue, cropped: false });
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item>
        <PicklistField field={useListConfig} onChange={onUseChange} />
        <IconButton onClick={onSubmitCrop}>
          <CropIcon />
        </IconButton>
        <div>
          X: {crop?.x} Y: {crop?.y} Width: {crop?.width} Height: {crop?.height}
        </div>
        {!editValue.cropped && (
          <ReactCrop
            crop={crop}
            onChange={onCropChange}
            aspect={1}
            style={{ width: '5000px', height: '5000px' }}
          >
            <img
              ref={imgRef}
              src={urlLoadable.contents}
              crossOrigin={'use-credentials'}
            />
          </ReactCrop>
        )}
        <canvas
          ref={previewCanvasRef}
          height={crop?.height}
          width={crop?.width}
          style={{ objectFit: 'contain' }}
        />
      </Grid>
    </Grid>
  );
}
