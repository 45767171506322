/* eslint-disable require-jsdoc */
import { AppBar, Button, Toolbar } from '@mui/material';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { leftBarState } from '../state';
import { GlobalActions } from './GlobalActions';
import { AssetViewBar } from './assets';

interface TopBarProps {
  signOut: () => void;
  user: { username: string; attributes: { email: string } };
}

function getButton(label: string, onClick: () => void) {
  return (
    <Button
      color="inherit"
      onClick={onClick}
      style={{ marginLeft: 'auto', marginRight: '0' }}
    >
      {label}
    </Button>
  );
}

export function TopBar(props: TopBarProps) {
  const sideBarOpen = useRecoilValue(leftBarState);
  const buttonConfigs = [
    {
      label: 'Sign Out',
      onClick: props.signOut,
    },
  ];
  return (
    <AppBar
      position="fixed"
      style={{ width: window.innerWidth - (sideBarOpen ? 240 : 64) }}
    >
      <Toolbar>
        {useLocation().pathname === '/assets' ? <AssetViewBar /> : <></>}
        <GlobalActions />
        <div style={{ marginLeft: 'auto', marginRight: '0' }}>
          {buttonConfigs.map(config => getButton(config.label, config.onClick))}
        </div>
      </Toolbar>
    </AppBar>
  );
}
