/* eslint-disable require-jsdoc */
import { AWS_CONFIG } from '../gen/aws';
import { ActionExecution, BlamAction, RemoteAgent } from '../models';
import { ApiService } from '../utils';

const apiConfig = AWS_CONFIG.api.gateways.actions;

export class ActionService extends ApiService {
  constructor() {
    super(apiConfig.name, apiConfig.path);
  }

  public async startAction(
    actionId: string,
    assetId = '',
    parameters: any = {},
    use = '',
  ): Promise<ActionExecution> {
    return this.apiPost(`execute/${actionId}`, {
      parameters: parameters,
      assetId: assetId,
      assetUse: use,
    });
  }

  public async listExecutions(): Promise<ActionExecution[]> {
    return this.apiGet('executions');
  }

  public async listActions(): Promise<BlamAction[]> {
    return this.apiGet();
  }

  public async listAgents(): Promise<RemoteAgent[]> {
    return this.apiGet('agents');
  }

  public getAssetExecutions(assetId: string): Promise<ActionExecution[]> {
    return this.apiGet(`asset/${assetId}`);
  }
}
