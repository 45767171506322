import { DataGrid, GridColDef } from '@mui/x-data-grid';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';

import { AssetCollection } from '../../../models/asset';
import { collectionsState } from '../../../state';

const columns: GridColDef<AssetCollection>[] = [
  { field: 'name', headerName: 'Name', width: 300 },
  {
    field: 'count',
    headerName: 'Count',
    width: 75,
    valueGetter: params => params.row.assets?.length,
  },
  { field: 'collectionId', headerName: 'ID', width: 300 },
];

export function CollectionList() {
  const collections =
    useRecoilValueLoadable<AssetCollection[]>(collectionsState);
  const navigate = useNavigate();
  return (
    <div style={{ height: `${window.innerHeight - 60}px`, width: '80%' }}>
      <DataGrid
        getRowId={row => row.collectionId || ''}
        rows={collections.state === 'hasValue' ? collections.contents : []}
        columns={columns}
        rowsPerPageOptions={[20, 35, 50]}
        onRowDoubleClick={params =>
          navigate(`/assets/collection/${params.row.collectionId}`)
        }
      />
    </div>
  );
}
