import { FormControl, MenuItem, TextField } from '@mui/material';
import * as React from 'react';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';

import { MetadataField } from '../../../models';
import {
  metadataFields,
  metadataSearchField,
  searchQueryString,
} from '../../../state';

interface MetadataSearchProps {
  key: string;
}

const operators = [
  { value: 'eq', label: 'Equals' },
  { value: 'ne', label: 'Not Equals' },
  { value: 'gt', label: 'Greater Than' },
  { value: 'lt', label: 'Less Than' },
  { value: 'gte', label: 'Greater Than or Equal' },
  { value: 'lte', label: 'Less Than or Equal' },
  { value: 'like', label: 'Like' },
];

export function MetadataSearch(props: MetadataSearchProps) {
  const fields = useRecoilValueLoadable(metadataFields);
  const [searchField, setSearchField] = useRecoilState(
    metadataSearchField(props.key),
  );
  const [_, setSearchQuery] = useRecoilState(searchQueryString);
  const handleChange = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const value =
      field === 'field'
        ? fields.contents.find(
            (f: MetadataField) => f.key === event.target.value,
          )
        : event.target.value;
    setSearchField({ ...searchField, [field]: value });
  };
  if (searchField.field && searchField.operator && searchField.value) {
    setSearchQuery(
      `(${searchField.field.key},${searchField.operator},${searchField.value})`,
    );
  }
  return fields.state === 'hasValue' ? (
    <FormControl id={props.key + '-md-grp'}>
      <TextField
        id={props.key + '-md-key'}
        select
        label="Select"
        helperText="Please select a field"
        onChange={event => handleChange('field', event)}
      >
        {fields.contents.map(option => (
          <MenuItem
            key={option.key}
            value={option.key}
            selected={option.key === searchField?.field?.key}
          >
            {option.key}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id={props.key + '-md-op'}
        select
        label="Select"
        helperText="Please select an operator"
        onChange={event => handleChange('operator', event)}
      >
        {operators.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {searchField?.field?.options ? (
        <TextField
          id={props.key + '-md-val'}
          select
          label="Select"
          helperText="Please select an option"
          onChange={event => handleChange('value', event)}
        >
          {searchField.field.options.map((option, idx) => (
            <MenuItem key={idx} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <TextField
          id={props.key + '-md-val'}
          onBlur={event => handleChange('value', event)}
        />
      )}
    </FormControl>
  ) : (
    <div>Loading...</div>
  );
}
