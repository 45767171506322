import { Box, Dialog, DialogTitle } from '@mui/material';
import * as React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { globalFormModalDetails, globalFormModalState } from '../../state';
import { GenericForm } from './GenericForm';

export function GlobalFormModal() {
  const [open, setOpen] = useRecoilState(globalFormModalState);
  const modalDetails = useRecoilValue(globalFormModalDetails);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{modalDetails?.title}</DialogTitle>
      <Box>
        <GenericForm
          formConfig={modalDetails}
          afterSubmit={() => setOpen(false)}
        />
      </Box>
    </Dialog>
  );
}
