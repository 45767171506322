import { atom, atomFamily, selector } from 'recoil';

import {
  AssetCollection,
  AssetLocation,
  AssetMetadata,
  AssetStore,
  FieldSearchParam,
} from '../models';
import { AssetService } from '../services';
import { runWithRetries } from '../utils';

export const assetsState = selector<AssetStore[]>({
  key: 'assets',
  get: ({ get }) =>
    new Promise<AssetStore[]>(resolve => {
      const query = get(searchQueryString);
      const assetService = new AssetService();
      runWithRetries(() => assetService.getAssets(query), 3, 250)
        .then(res => resolve(res))
        .catch(_err => resolve([]));
    }),
});

export const selectedAssetsState = atom<string[]>({
  key: 'selectedAssets',
  default: [],
});

export const pageParams = atom({
  key: 'pageParams',
  default: {
    page: 0,
    pageSize: 25,
  },
});

export const tileViewAssets = selector<AssetStore[]>({
  key: 'tileViewAssets',
  get: ({ get }) => {
    const assets = get(assetsState);
    const pageInfo = get(pageParams);
    return assets.slice(
      pageInfo.page * pageInfo.pageSize,
      (pageInfo.page + 1) * pageInfo.pageSize,
    );
  },
});

export const downloadUrlStateFamily = atomFamily<
  string,
  { id: string; use: string }
>({
  key: 'downloadUrlState',
  default: (details: { id: string; use: string }) =>
    new Promise(resolve => {
      const assetService = new AssetService();
      runWithRetries(
        () => assetService.getAssetUrl(details.id, details.use),
        3,
        5000,
      )
        .then(res => resolve(res.downloadUrl))
        .catch(() => resolve(''));
    }),
});

export const metadataSearchField = atomFamily<FieldSearchParam, string>({
  key: 'mdSearchField',
  default: {},
});

export const searchQueryString = atom<string>({
  key: 'searchQueryString',
  default: '',
});

export const collectionsState = atom<AssetCollection[]>({
  key: 'collections',
  default: new Promise<AssetCollection[]>(resolve => {
    const assetService = new AssetService();
    runWithRetries(() => assetService.getCollections(), 3, 250)
      .then(res => resolve(res))
      .catch(_err => resolve([]));
  }),
});

export const collectionViewState = atomFamily<AssetStore[], string>({
  key: 'collectionViewState',
  default: (collectionId: string) =>
    new Promise(resolve => {
      const assetService = new AssetService();
      runWithRetries(
        () => assetService.getCollectionAssets(collectionId),
        3,
        5000,
      )
        .then(res => resolve(res))
        .catch(() => resolve([]));
    }),
});

export const assetState = atomFamily<
  | { asset: AssetStore; locations: AssetLocation[]; metadata: AssetMetadata[] }
  | undefined,
  string
>({
  key: 'assetState',
  default: (assetId: string) =>
    new Promise(resolve => {
      const assetService = new AssetService();
      runWithRetries(() => assetService.getAsset(assetId), 3, 5000)
        .then(res => resolve(res))
        .catch(() => resolve(undefined));
    }),
});
