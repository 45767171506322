import { Button, Stack, TextField } from '@mui/material';
import * as React from 'react';

import { FormConfig, FormFieldConfig } from '../../models';
import { PicklistField } from './PicklistField';

interface GenericFormProps {
  formConfig: FormConfig;
  afterSubmit?: () => void;
}

export function GenericForm(props: GenericFormProps) {
  const [formValues, setFormValues] = React.useState({});
  const afterSubmit = () => {
    if (props.afterSubmit) {
      props.afterSubmit();
    }
  };
  const onFieldChange = (key: string, value: any) => {
    setFormValues({ ...formValues, [key]: value });
  };
  const mapField = (fieldConfig: FormFieldConfig) => {
    if (fieldConfig.picklistConfig) {
      return (
        <PicklistField
          field={fieldConfig}
          onChange={event => onFieldChange(fieldConfig.key, event.target.value)}
        />
      );
    } else {
      return (
        <TextField
          id={fieldConfig.key}
          onChange={event => onFieldChange(fieldConfig.key, event.target.value)}
          style={{ minWidth: 300 }}
          label={fieldConfig.name || fieldConfig.key}
          required={fieldConfig.required}
          defaultValue={fieldConfig.default}
        />
      );
    }
  };
  return props.formConfig ? (
    <div style={{ padding: 20 }}>
      <Stack spacing={2}>{props.formConfig.parameters.map(mapField)}</Stack>
      <Button
        onClick={() =>
          props.formConfig.onSubmit(formValues).finally(afterSubmit)
        }
        style={{ minWidth: 300, paddingTop: 20 }}
      >
        Submit
      </Button>
    </div>
  ) : (
    <></>
  );
}
