import { Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import * as React from 'react';
import { useRecoilValueLoadable } from 'recoil';

import { ConnectedAccount } from '../../models';
import { AccountService } from '../../services';
import { connectedAccountsState } from '../../state';

export function AccountList() {
  const accountsService = new AccountService();
  const accounts = useRecoilValueLoadable<ConnectedAccount[]>(
    connectedAccountsState,
  );
  const columns: GridColDef<ConnectedAccount>[] = [
    { field: 'id', headerName: 'Account Id', width: 200 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'accountType', headerName: 'Type', width: 200 },
    {
      field: 'refreshToken',
      headerName: 'Refresh Token',
      width: 200,
      renderCell: params =>
        params.row.accountType === 'google' ? (
          <Button onClick={() => accountsService.authorizeGoogle()}>
            Refresh Token
          </Button>
        ) : (
          <></>
        ),
    },
  ];
  return (
    <div style={{ height: `${window.innerHeight - 60}px`, width: '100%' }}>
      <DataGrid
        rows={accounts.state === 'hasValue' ? accounts.contents : []}
        columns={columns}
        pageSize={25}
        getRowId={row => row.name}
      />
    </div>
  );
}
