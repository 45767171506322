import { ImageList, Pagination } from '@mui/material';
import * as React from 'react';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { AssetStore } from '../../models';
import { assetsState, pageParams, tileViewAssets } from '../../state';
import { AssetTile } from './AssetTile';

function calculateTileLayout() {
  const workingWidth = window.innerWidth - 100;
  const workingHeight = window.innerHeight - 100;
  const imgSize = 512;
  return {
    cols: Math.floor(workingWidth / imgSize),
    width: workingWidth,
    height: workingHeight,
    imgSize: imgSize,
    imgSizeStr: `${imgSize}px`,
  };
}

export function TileView() {
  const allAssets = useRecoilValueLoadable<AssetStore[]>(assetsState);
  if (allAssets.state !== 'hasValue') {
    return <div>Loading...</div>;
  }
  const assets = useRecoilValue<AssetStore[]>(tileViewAssets);
  const [pageConfig, setPageConfig] = useRecoilState(pageParams);
  const tileLayout = calculateTileLayout();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <ImageList
        sx={{ width: tileLayout.width }}
        cols={tileLayout.cols}
        rowHeight={tileLayout.imgSize}
      >
        {assets.map(asset => (
          <AssetTile
            key={asset.id}
            asset={asset}
            sideLength={tileLayout.imgSizeStr}
          />
        ))}
      </ImageList>
      <div style={{ width: '100%', alignContent: 'center' }}>
        <Pagination
          page={pageConfig.page}
          count={Math.floor(allAssets.contents.length / pageConfig.pageSize)}
          onChange={(_event, page) =>
            setPageConfig({ ...pageConfig, page: page })
          }
        />
      </div>
    </div>
  );
}
