import { atom, atomFamily } from 'recoil';

import { ActionExecution, BlamAction, RemoteAgent } from '../models';
import { ActionService } from '../services';
import { runWithRetries } from '../utils';

export const actionsState = atom<BlamAction[]>({
  key: 'actions',
  default: new Promise<BlamAction[]>(resolve => {
    const actionService = new ActionService();
    runWithRetries(() => actionService.listActions(), 3, 250)
      .then(res => resolve(res))
      .catch(_err => resolve([]));
  }),
});

export const actionExecutionState = atom<ActionExecution[]>({
  key: 'actionExecs',
  default: new Promise<ActionExecution[]>(resolve => {
    const actionService = new ActionService();
    runWithRetries(() => actionService.listExecutions(), 3, 250)
      .then(res => resolve(res))
      .catch(_err => resolve([]));
  }),
});

export const remoteAgentsState = atom<RemoteAgent[]>({
  key: 'remoteAgents',
  default: new Promise<RemoteAgent[]>(resolve => {
    const actionService = new ActionService();
    runWithRetries(() => actionService.listAgents(), 3, 250)
      .then(res => resolve(res))
      .catch(_err => resolve([]));
  }),
});

export const assetExecutionState = atomFamily<ActionExecution[], string>({
  key: 'assetActionExecs',
  default: (id: string) =>
    new Promise(resolve => {
      const actionService = new ActionService();
      runWithRetries(() => actionService.getAssetExecutions(id), 3, 5000)
        .then(resolve)
        .catch(() => resolve([]));
    }),
});
