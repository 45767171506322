import { atom } from 'recoil';

import { MetadataField } from '../models';
import { MetadataService } from '../services';
import { runWithRetries } from '../utils';

export const metadataFields = atom<MetadataField[]>({
  key: 'fields',
  default: new Promise<MetadataField[]>(resolve => {
    const metadataService = new MetadataService();
    runWithRetries(() => metadataService.getFields(), 3, 250)
      .then(res => resolve(res))
      .catch(err => resolve([]));
  }),
});
