import { DataGrid, GridColDef } from '@mui/x-data-grid';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';

import { AssetStore } from '../../models';
import { assetsState, pageParams, selectedAssetsState } from '../../state';
import { AssetActions } from './AssetActions';

export const columns: GridColDef<AssetStore>[] = [
  { field: 'assetType', headerName: 'Type', width: 70 },
  { field: 'name', headerName: 'Name', width: 400 },
  {
    field: 'seed',
    headerName: 'Seed',
    width: 70,
    valueGetter: params => params.row.metadata.seed,
  },
  {
    field: 'prompt',
    headerName: 'Prompt',
    width: 400,
    valueGetter: params => params.row.metadata.prompt,
  },
  {
    field: 'stable_diffusion_model',
    headerName: 'SD Model',
    width: 70,
    valueGetter: params => params.row.metadata.stable_diffusion_model,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    renderCell: params => <AssetActions asset={params.row} />,
  },
];

export function AssetList() {
  const navigate = useNavigate();
  const assets = useRecoilValueLoadable<AssetStore[]>(assetsState);
  const [pageConfig, setPageConfig] = useRecoilState(pageParams);
  const [_, setSelectedAssetIds] =
    useRecoilState<string[]>(selectedAssetsState);
  const onSelectionModelChange = (newSelectionModel: any) => {
    setSelectedAssetIds(newSelectionModel);
  };
  return (
    <div style={{ height: `${window.innerHeight - 60}px`, width: '80%' }}>
      <DataGrid
        rows={assets.state === 'hasValue' ? assets.contents : []}
        columns={columns}
        page={pageConfig.page}
        pageSize={pageConfig.pageSize}
        rowsPerPageOptions={[20, 35, 50]}
        onSelectionModelChange={onSelectionModelChange}
        onPageChange={page => setPageConfig({ ...pageConfig, page: page })}
        onPageSizeChange={pageSize =>
          setPageConfig({ ...pageConfig, pageSize: pageSize })
        }
        onRowDoubleClick={params => navigate(`/assets/${params.row.id}`)}
      />
    </div>
  );
}
