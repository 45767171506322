import { atom } from 'recoil';

import { FormConfig } from '../models';

export const globalFormModalState = atom({
  key: 'globalFormModalState',
  default: false,
});

export const globalFormModalDetails = atom<FormConfig>({
  key: 'globalFormModalDetails',
  default: undefined,
});
