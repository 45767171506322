import { Auth } from 'aws-amplify';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import SwaggerUI, { SwaggerUIProps } from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

import { AWS_CONFIG } from '../../gen/aws';
import { SwaggerNav } from './SwaggerNav';
import './myswagger.css';
import './swagger.css';

export function SwaggerView() {
  const { id } = useParams();
  const apiName = id ? id : 'assets';
  const selectedApi = AWS_CONFIG.api.gateways[apiName];
  const swaggerProps: SwaggerUIProps = {
    spec: selectedApi.spec,
    requestInterceptor: async (req: any) => {
      const jwt = (await Auth.currentSession()).getIdToken().getJwtToken();
      req.headers['Authorization'] = `Bearer ${jwt}`;
      return req;
    },
  };
  return (
    <div>
      <SwaggerNav />
      <SwaggerUI {...swaggerProps} />
    </div>
  );
}
