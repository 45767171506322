export async function wait(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export async function runWithRetries<T>(
  fn: () => Promise<any>,
  retries = 5,
  delay = 1000,
  id = '',
): Promise<any> {
  let lastError;
  for (let i = 0; i < retries; i++) {
    try {
      return await fn();
    } catch (e) {
      lastError = e;
      await wait(delay);
    }
  }
  throw lastError;
}
