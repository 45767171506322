import { ImageListItem, ImageListItemBar } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { AssetStore } from '../../models';
import { tileHoverState } from '../../state';
import { AssetActions } from './AssetActions';
import { AssetPreview } from './AssetPreview';

export function AssetTile(props: { asset: AssetStore; sideLength: string }) {
  const [titleVisible, setTitleVisible] = useRecoilState(
    tileHoverState(props.asset.id),
  );
  const navigate = useNavigate();
  return (
    <ImageListItem
      key={props.asset.id}
      onMouseLeave={() => setTitleVisible(false)}
      onMouseEnter={() => setTitleVisible(true)}
      onClick={() => navigate(`/assets/${props.asset.id}`)}
    >
      <AssetPreview
        key={props.asset.id}
        asset={props.asset}
        height={props.sideLength}
        width={props.sideLength}
      />
      <ImageListItemBar
        title={props.asset.name}
        sx={{
          visibility: titleVisible ? 'visible' : 'hidden',
          width: props.sideLength,
        }}
        actionIcon={<AssetActions asset={props.asset} />}
      />
    </ImageListItem>
  );
}
