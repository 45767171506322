import { AwsConfig } from '../models/aws';

export const AWS_CONFIG: AwsConfig = {
  cognito: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_yRi3I4IjZ',
    userPoolWebClientId: '3r49d9vp4oi7asdmsrahd0i07f',
  },
  api: {
    baseUrl: 'https://api.blam.jaguar-ai.com',
    gateways: {
      misc: {
        name: 'dev-misc',
        path: '/misc',
        spec: {
          openapi: '3.0.1',
          info: {
            title: 'misc',
            version: '1.0',
          },
          servers: [
            {
              url: 'https://api.blam.jaguar-ai.com/misc',
            },
          ],
          paths: {
            '/preferences': {
              get: {
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              put: {
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
          },
          components: {
            securitySchemes: {
              CognitoAuthorizer: {
                type: 'apiKey',
                name: 'Authorization',
                in: 'header',
                'x-amazon-apigateway-authtype': 'cognito_user_pools',
              },
            },
          },
        },
      },
      metadata: {
        name: 'dev-metadata',
        path: '/metadata',
        spec: {
          openapi: '3.0.1',
          info: {
            title: 'metadata',
            version: '1.0',
          },
          servers: [
            {
              url: 'https://api.blam.jaguar-ai.com/metadata',
            },
          ],
          paths: {
            '/': {
              get: {
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
          },
          components: {
            securitySchemes: {
              CognitoAuthorizer: {
                type: 'apiKey',
                name: 'Authorization',
                in: 'header',
                'x-amazon-apigateway-authtype': 'cognito_user_pools',
              },
            },
          },
        },
      },
      connector: {
        name: 'dev-connector',
        path: '/connections',
        spec: {
          openapi: '3.0.1',
          info: {
            title: 'connector',
            version: '1.0',
          },
          servers: [
            {
              url: 'https://api.blam.jaguar-ai.com/connections',
            },
          ],
          paths: {
            '/google/auth': {
              get: {
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
            '/google/callback': {
              get: {},
              options: {
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
            '/google/media-items': {
              get: {
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
            '/': {
              get: {
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              post: {
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
          },
          components: {
            securitySchemes: {
              CognitoAuthorizer: {
                type: 'apiKey',
                name: 'Authorization',
                in: 'header',
                'x-amazon-apigateway-authtype': 'cognito_user_pools',
              },
            },
          },
        },
      },
      storage: {
        name: 'dev-storage',
        path: '/storage',
        spec: {
          openapi: '3.0.1',
          info: {
            title: 'storage',
            version: '1.0',
          },
          servers: [
            {
              url: 'https://api.blam.jaguar-ai.com/storage',
            },
          ],
          paths: {
            '/upload': {
              get: {
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
            '/': {
              get: {
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              post: {
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
          },
          components: {
            securitySchemes: {
              CognitoAuthorizer: {
                type: 'apiKey',
                name: 'Authorization',
                in: 'header',
                'x-amazon-apigateway-authtype': 'cognito_user_pools',
              },
            },
          },
        },
      },
      actions: {
        name: 'dev-actions',
        path: '/action',
        spec: {
          openapi: '3.0.1',
          info: {
            title: 'actions',
            version: '1.0',
          },
          servers: [
            {
              url: 'https://api.blam.jaguar-ai.com/action',
            },
          ],
          paths: {
            '/queue': {
              post: {
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
            '/execution/{id}/asset/{asset_id}': {
              put: {
                parameters: [
                  {
                    name: 'asset_id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                parameters: [
                  {
                    name: 'asset_id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
            '/asset/{id}': {
              get: {
                parameters: [
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                parameters: [
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
            '/agents': {
              get: {
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              post: {
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
            '/agents/{id}': {
              put: {
                parameters: [
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                parameters: [
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
            '/queue/{name}/login': {
              get: {
                parameters: [
                  {
                    name: 'name',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                parameters: [
                  {
                    name: 'name',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
            '/execute/{id}': {
              post: {
                parameters: [
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                parameters: [
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
            '/execution/{id}/status/{status}': {
              put: {
                parameters: [
                  {
                    name: 'status',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                parameters: [
                  {
                    name: 'status',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
            '/executions': {
              get: {
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
            '/': {
              get: {
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
          },
          components: {
            securitySchemes: {
              CognitoAuthorizer: {
                type: 'apiKey',
                name: 'Authorization',
                in: 'header',
                'x-amazon-apigateway-authtype': 'cognito_user_pools',
              },
            },
          },
        },
      },
      iam: {
        name: 'dev-iam',
        path: '/iam',
        spec: {
          openapi: '3.0.1',
          info: {
            title: 'iam',
            version: '1.0',
          },
          servers: [
            {
              url: 'https://api.blam.jaguar-ai.com/iam',
            },
          ],
          paths: {
            '/org': {
              post: {
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
          },
          components: {
            securitySchemes: {
              CognitoAuthorizer: {
                type: 'apiKey',
                name: 'Authorization',
                in: 'header',
                'x-amazon-apigateway-authtype': 'cognito_user_pools',
              },
            },
          },
        },
      },
      assets: {
        name: 'dev-assets',
        path: '/asset',
        spec: {
          openapi: '3.0.1',
          info: {
            title: 'assets',
            version: '1.0',
          },
          servers: [
            {
              url: 'https://api.blam.jaguar-ai.com/asset',
            },
          ],
          paths: {
            '/download/{id}': {
              get: {
                parameters: [
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                parameters: [
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
            '/{id}/locations': {
              post: {
                parameters: [
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                parameters: [
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
            '/collection/{id}': {
              get: {
                parameters: [
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                parameters: [
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
            '/metadata/{id}': {
              put: {
                parameters: [
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                parameters: [
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
            '/collection/{id}/remove': {
              put: {
                parameters: [
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                parameters: [
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
            '/detailed/{id}': {
              get: {
                parameters: [
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                parameters: [
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
            '/': {
              get: {
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              post: {
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
            '/collection': {
              get: {
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              post: {
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
            '/collection/{id}/add': {
              put: {
                parameters: [
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                security: [
                  {
                    CognitoAuthorizer: [],
                  },
                ],
              },
              options: {
                parameters: [
                  {
                    name: 'id',
                    in: 'path',
                    required: true,
                    schema: {
                      type: 'string',
                    },
                  },
                ],
                responses: {
                  '200': {
                    description: '200 response',
                    headers: {
                      'Access-Control-Allow-Origin': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Methods': {
                        schema: {
                          type: 'string',
                        },
                      },
                      'Access-Control-Allow-Headers': {
                        schema: {
                          type: 'string',
                        },
                      },
                    },
                    content: {},
                  },
                },
              },
            },
          },
          components: {
            securitySchemes: {
              CognitoAuthorizer: {
                type: 'apiKey',
                name: 'Authorization',
                in: 'header',
                'x-amazon-apigateway-authtype': 'cognito_user_pools',
              },
            },
          },
        },
      },
    },
  },
};
