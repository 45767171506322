import * as React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { asseViewState, searchBoxState } from '../../state';
import { AssetList } from './AssetList';
import { AssetView } from './AssetView';
import { TileView } from './TileView';
import { AssetSearch } from './search';

export function Assets() {
  const searchOpen = useRecoilValue(searchBoxState);
  const assetView = useRecoilValueLoadable(asseViewState);
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {searchOpen ? <AssetSearch /> : <div></div>}
      <div style={{ height: '100%', width: '100%', display: 'flex' }}>
        {assetView.contents == 'tile' ? (
          <TileView />
        ) : (
          <>
            <AssetList />
            <div style={{ height: '100%', width: '400px' }}>
              <AssetView />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
