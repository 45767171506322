import { Button, Menu, MenuItem } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { AWS_CONFIG } from '../../gen/aws';

export function SwaggerNav() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onSelect = (apiName: string) => {
    navigate(`/api-docs/${apiName}`);
    handleClose();
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Service
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {Object.keys(AWS_CONFIG.api.gateways).map(apiName => (
          <MenuItem key={apiName} onClick={() => onSelect(apiName)}>
            {apiName}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
