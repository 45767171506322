import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';

import { AssetStore } from '../../../models/asset';
import { collectionViewState, selectedAssetsState } from '../../../state';
import { columns } from '../AssetList';
import { AssetPreview } from '../AssetPreview';

export function CollectionView() {
  const { id } = useParams();
  const collectionAssets = useRecoilValueLoadable<AssetStore[]>(
    collectionViewState(id || ''),
  );
  const [selectedAssetIds, setSelectedAssetIds] =
    useRecoilState<string[]>(selectedAssetsState);
  const onSelectionModelChange = (newSelectionModel: any) => {
    setSelectedAssetIds(newSelectionModel);
  };
  return (
    <div style={{ height: `${window.innerHeight - 60}px`, width: '100%' }}>
      <div
        style={{
          height: '50%',
          alignContent: 'center',
          verticalAlign: 'middle',
        }}
      >
        {selectedAssetIds.length == 1 ? (
          <AssetPreview
            asset={collectionAssets.contents.find(
              (asset: AssetStore) => asset.id == selectedAssetIds[0],
            )}
          />
        ) : null}
      </div>
      <DataGrid
        rows={
          collectionAssets.state === 'hasValue' ? collectionAssets.contents : []
        }
        columns={columns}
        rowsPerPageOptions={[20, 35, 50]}
        onSelectionModelChange={onSelectionModelChange}
        isRowSelectable={() => true}
      />
    </div>
  );
}
