import { DataGrid, GridColDef } from '@mui/x-data-grid';
import * as React from 'react';
import { useRecoilValueLoadable } from 'recoil';

import { RemoteAgent } from '../../models';
import { remoteAgentsState } from '../../state';

export function AgentList() {
  const agents = useRecoilValueLoadable<RemoteAgent[]>(remoteAgentsState);
  const columns: GridColDef<RemoteAgent>[] = [
    { field: 'id', headerName: 'Agent Id', width: 200, resizable: true },
    { field: 'name', headerName: 'Name', width: 150, resizable: true },
    { field: 'commands', headerName: 'Commands', width: 200, resizable: true },
    { field: 'actionIds', headerName: 'Actions', width: 200, resizable: true },
  ];
  return (
    <div style={{ height: `${window.innerHeight - 60}px`, width: '100%' }}>
      <DataGrid
        rows={agents.state === 'hasValue' ? agents.contents : []}
        columns={columns}
        pageSize={25}
      />
    </div>
  );
}
