import {
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Grid from '@mui/material/Grid/Grid';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';

import { BlamAction, parseMetadataAndLocations } from '../../models';
import { actionsState, assetExecutionState, assetState } from '../../state';
import { AssetActions } from './AssetActions';
import { AssetLocationAccordian } from './AssetLocationAccordian';
import { AssetPreview } from './AssetPreview';

export function SingleAssetView() {
  const { id } = useParams();
  const actions = useRecoilValueLoadable<BlamAction[]>(actionsState);
  const asset = useRecoilValueLoadable(assetState(id || ''));
  const assetExecutions = useRecoilValueLoadable(assetExecutionState(id || ''));
  const [savValues, setSavValues] = React.useState({
    use: 'proxy',
    imageProps: { height: '100%', width: '100%' },
  });
  const onSizeLockChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    locked: boolean,
  ) => {
    if (locked) {
      console.log('locked');
      setSavValues({
        ...savValues,
        imageProps: { height: '1024px', width: '1024px' },
      });
    } else {
      console.log('unlocked');
      setSavValues({
        ...savValues,
        imageProps: { height: '100%', width: '100%' },
      });
    }
  };
  if (asset.state != 'hasValue' || !asset.contents) {
    return <div>Loading...</div>;
  }
  const mdByLocation = parseMetadataAndLocations(
    asset.contents.locations,
    asset.contents.metadata,
  );
  const locations = Object.keys(mdByLocation).map(loc => (
    <AssetLocationAccordian
      key={loc}
      title={loc}
      metadata={mdByLocation[loc].metadata}
      location={mdByLocation[loc].location}
      onClickCallback={() => setSavValues({ ...savValues, use: loc })}
    />
  ));
  const actionRows =
    assetExecutions.state == 'hasValue' ? (
      assetExecutions.contents.map((execution, idx) => (
        <TableRow key={idx}>
          <TableCell>
            {actions.state === 'hasValue'
              ? actions.contents.find(a => a.id === execution.actionId)?.name
              : ''}
          </TableCell>
          <TableCell>{execution.status}</TableCell>
        </TableRow>
      ))
    ) : (
      <TableRow>
        <TableCell colSpan={2}>Loading...</TableCell>
      </TableRow>
    );
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <AssetActions asset={asset.contents.asset} />
        <Switch aria-label="Lock size" onChange={onSizeLockChange} />
        <AssetPreview
          asset={asset.contents.asset}
          use={savValues.use}
          height={savValues.imageProps.height}
          width={savValues.imageProps.width}
        />
      </Grid>
      <Grid item xs={4}>
        {locations}
      </Grid>
      <Grid item xs={4}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow key="name">
                <TableCell width="150px">Name</TableCell>
                <TableCell width="250px">{asset.contents.asset.name}</TableCell>
              </TableRow>
              {Object.keys(asset.contents.asset.metadata).map(key => (
                <TableRow key={key}>
                  <TableCell width="150px">{key}</TableCell>
                  <TableCell width="250px">
                    {asset.contents?.asset.metadata[key]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={2}>
                  Action History
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{actionRows}</TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
