/* eslint-disable require-jsdoc */
import { AWS_CONFIG } from '../gen/aws';
import { StorageAccount } from '../models';
import { ApiService } from '../utils';

const apiConfig = AWS_CONFIG.api.gateways.storage;

export class StorageService extends ApiService {
  constructor() {
    super(apiConfig.name, apiConfig.path);
  }

  public async getAccounts(): Promise<StorageAccount[]> {
    return await this.apiGet<StorageAccount[]>();
  }

  public async createAccount(account: StorageAccount): Promise<StorageAccount> {
    return await this.apiPost<any, StorageAccount>('', account);
  }
}
