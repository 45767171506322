import { DataGrid, GridColDef } from '@mui/x-data-grid';
import * as React from 'react';
import { useRecoilValueLoadable } from 'recoil';

import { ActionExecution, BlamAction } from '../../models';
import { actionExecutionState, actionsState } from '../../state';

export function ExecutionList() {
  const executions =
    useRecoilValueLoadable<ActionExecution[]>(actionExecutionState);
  const actions = useRecoilValueLoadable<BlamAction[]>(actionsState);
  const columns: GridColDef<ActionExecution>[] = [
    { field: 'id', headerName: 'Execution Id', width: 70 },
    {
      field: 'actionName',
      headerName: 'Action',
      width: 300,
      valueGetter: params => {
        const action =
          actions.state === 'hasValue'
            ? actions.contents.find(a => a.id === params.row.actionId)
            : undefined;
        return action ? action.name : '';
      },
    },
    { field: 'status', headerName: 'Status', width: 100 },
    {
      field: 'parameters',
      headerName: 'Parameters',
      width: 500,
      renderCell: params => <pre>{JSON.stringify(params.row.parameters)}</pre>,
    },
    { field: 'createdDate', headerName: 'Started', width: 200 },
    { field: 'updatedDate', headerName: 'Update', width: 200 },
    {
      field: 'assetId',
      headerName: 'Asset ID',
      width: 200,
      renderCell: params => (
        <a href={`/assets/${params.row.assetId}`}>{params.row.assetId}</a>
      ),
    },
    {
      field: 'Other Assets',
      width: 200,
      renderCell: params => {
        const assetIds = params.row.otherAssetIds;
        if (!assetIds) {
          return null;
        }
        return (
          <ul>
            {assetIds.map((id, idx: number) => (
              <>
                <a href={`/assets/${id}`}>{idx}</a>,
              </>
            ))}
          </ul>
        );
      },
    },
  ];
  return (
    <div style={{ height: `${window.innerHeight - 60}px`, width: '100%' }}>
      <DataGrid
        rows={executions.state === 'hasValue' ? executions.contents : []}
        columns={columns}
        pageSize={25}
      />
    </div>
  );
}
