import { atom } from 'recoil';

import { StorageAccount } from '../models';
import { StorageService } from '../services';
import { runWithRetries } from '../utils';

export const storageAccountsState = atom<StorageAccount[]>({
  key: 'storageAccounts',
  default: new Promise<StorageAccount[]>(resolve => {
    const storageService = new StorageService();
    runWithRetries(() => storageService.getAccounts(), 3, 250)
      .then(res => resolve(res))
      .catch(_err => resolve([]));
  }),
});
