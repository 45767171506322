export interface Asset {
  id?: string;
  userId?: string;
  name?: string;
  assetType?: string;
  metadata?: any;
}

export interface AssetStore extends Asset {
  id: string;
  assetType: string;
}

export interface AssetCollection {
  collectionId?: string;
  name?: string;
  assets?: string[];
}

export interface AssetLocation {
  id: string;
  // assetId: string;
  asset_id: string;
  path: string;
  url?: string;
  use: string;
}

export interface AssetMetadata {
  // assetId: string;
  // fieldKey: string;
  // fieldValue: string;
  // setBy: string;
  // locationId: string;
  asset_id: string;
  field_key: string;
  field_value: string;
  set_by: string;
  location_id: string;
}

export const parseMetadataAndLocations = (
  locations: AssetLocation[],
  metadata: AssetMetadata[],
) => {
  const metadataByLocation: {
    [key: string]: {
      metadata: { [key: string]: string };
      location: AssetLocation;
    };
  } = {};
  locations.forEach(location => {
    const locationMetadata: { [key: string]: string } = {};
    metadata
      .filter(md => md.location_id === location.id)
      .forEach(md => {
        locationMetadata[md.field_key] = md.field_value;
      });
    metadataByLocation[location.use] = {
      metadata: locationMetadata,
      location,
    };
  });
  return metadataByLocation;
};
