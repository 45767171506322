import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Box, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { Amplify, Auth } from 'aws-amplify';
import React from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import './App.css';
import {
  AccountList,
  AgentList,
  Assets,
  CollectionList,
  CollectionView,
  ExecutionList,
  ImageEdit,
  SideBar,
  SingleAssetView,
  SwaggerView,
  TopBar,
} from './components';
import { GlobalFormModal } from './components/forms';
import { AWS_CONFIG } from './gen/aws';

Amplify.configure({
  aws_cognito_region: AWS_CONFIG.cognito.region,
  aws_user_pools_id: AWS_CONFIG.cognito.userPoolId,
  aws_user_pools_web_client_id: AWS_CONFIG.cognito.userPoolWebClientId,
  API: {
    endpoints: Object.keys(AWS_CONFIG.api.gateways).map(key => ({
      name: AWS_CONFIG.api.gateways[key].name,
      endpoint: AWS_CONFIG.api.baseUrl,
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    })),
  },
});

/**
 * App Entry Point
 * @param {*} { user }
 * @return {*}  {JSX.Element}
 */
function App({ user, signOut }: any): JSX.Element {
  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <Router>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <SideBar />
            <TopBar user={user} signOut={signOut} />
            <div style={{ width: '100%', marginTop: 64 }}>
              <Routes>
                <Route path="/" element={<Navigate to="/assets" />} />
                <Route path="/assets" element={<Assets />} />
                <Route path="/assets/:id" element={<SingleAssetView />} />
                <Route path="/assets/:id/image-edit" element={<ImageEdit />} />
                <Route
                  path="/assets/collections"
                  element={<CollectionList />}
                />
                <Route
                  path="/assets/collection/:id"
                  element={<CollectionView />}
                />
                <Route path="/workflows" element={<ExecutionList />} />
                <Route path="/workflows/agents" element={<AgentList />} />
                <Route path="/connections" element={<AccountList />} />
                <Route path="/api-docs" element={<SwaggerView />} />
                <Route path="/api-docs/:id" element={<SwaggerView />} />
              </Routes>
            </div>
          </Box>
        </Router>
        <GlobalFormModal />
      </ThemeProvider>
    </RecoilRoot>
  );
}
export default withAuthenticator(App, {
  hideSignUp: true,
});
