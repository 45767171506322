import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import * as React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { AssetStore } from '../../models';
import { assetsState, selectedAssetsState } from '../../state';
import { AssetPreview } from './AssetPreview';

export function AssetView() {
  const assets = useRecoilValueLoadable<AssetStore[]>(assetsState);
  const selectedAssetIds = useRecoilValue<string[]>(selectedAssetsState);
  const noSelectionBody = <div>No Selection</div>;
  const multiSelectBody = <div>Multiple Selection</div>;
  const getSingleView = () => {
    const asset = assets.contents.find(
      (asset: AssetStore) => asset.id === selectedAssetIds[0],
    );
    return (
      <>
        <AssetPreview asset={asset ? asset : { id: '' }} />
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow key="name">
                <TableCell width="150px">Name</TableCell>
                <TableCell width="250px">{asset?.name}</TableCell>
              </TableRow>
              {Object.keys(asset?.metadata).map(key => (
                <TableRow key={key}>
                  <TableCell width="150px">{key}</TableCell>
                  <TableCell width="250px">{asset?.metadata[key]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };
  return (
    <div style={{ height: '100%', width: '100%' }}>
      {!selectedAssetIds.length
        ? noSelectionBody
        : selectedAssetIds.length > 1
        ? multiSelectBody
        : getSingleView()}
    </div>
  );
}
