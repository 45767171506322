import { atom } from 'recoil';

import { ConnectedAccount } from '../models';
import { AccountService } from '../services';
import { runWithRetries } from '../utils';

export const connectedAccountsState = atom<ConnectedAccount[]>({
  key: 'connectedAccountsState',
  default: new Promise<ConnectedAccount[]>(resolve => {
    const accountService = new AccountService();
    runWithRetries(() => accountService.getConnectedAccounts(), 3, 250)
      .then(res => resolve(res))
      .catch(_err => resolve([]));
  }),
});
