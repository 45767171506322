import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Loadable, useRecoilState, useRecoilValueLoadable } from 'recoil';

import { AssetStore, BlamAction } from '../../models';
import { AssetService } from '../../services';
import {
  actionsState,
  collectionsState,
  globalFormModalDetails,
  globalFormModalState,
} from '../../state';
import { convertAction } from '../../utils/action';

interface AssetActionsProps {
  asset: AssetStore;
}

export function AssetActions(props: AssetActionsProps) {
  const navigate = useNavigate();
  const assetService = new AssetService();
  const remoteActions: Loadable<BlamAction[]> =
    useRecoilValueLoadable(actionsState);
  const collections = useRecoilValueLoadable(collectionsState);
  const [_2, setGlobalActionOpen] = useRecoilState(globalFormModalState);
  const [_3, setGlobalActionDetails] = useRecoilState(globalFormModalDetails);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const actions = [
    {
      label: 'Add to Collection',
      action: (asset: AssetStore) => {
        setGlobalActionDetails({
          title: `Add ${asset.name} to Collection`,
          onSubmit: async params => {
            await assetService.addAssetsToCollection(
              [asset.id],
              params['collectionId'],
            );
          },
          parameters: [
            {
              key: 'collectionId',
              name: 'Collection',
              picklistConfig: {
                optionsLoadable: collections,
                optionLabelKey: 'name',
                optionValueKey: 'collectionId',
              },
              required: true,
              type: 'picklist',
            },
          ],
        });
        setGlobalActionOpen(true);
      },
    },
  ];
  if (props.asset.assetType || (props.asset as any).asset_type == 'image') {
    actions.push({
      label: 'Edit Image',
      action: (asset: AssetStore) => navigate(`/assets/${asset.id}/image-edit`),
    });
  }
  if (remoteActions.state === 'hasValue') {
    actions.push(
      ...remoteActions.contents
        .filter(
          action =>
            action.requiresAsset &&
            !action.hidden &&
            action.targetTypes.includes(
              props.asset.assetType || (props.asset as any).asset_type,
            ),
        )
        .map(action =>
          convertAction(action, setGlobalActionDetails, () =>
            setGlobalActionOpen(true),
          ),
        ),
    );
  }
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Actions
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {actions.map((action, idx) => (
          <MenuItem
            key={idx}
            onClick={() => {
              action.action(props.asset);
              handleClose();
            }}
          >
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
