/* eslint-disable require-jsdoc */
import GridViewIcon from '@mui/icons-material/GridView';
import SearchIcon from '@mui/icons-material/Search';
import ViewListIcon from '@mui/icons-material/ViewList';
import { IconButton, Toolbar } from '@mui/material';
import * as React from 'react';
import { useRecoilState, useRecoilStateLoadable } from 'recoil';

import { asseViewState, searchBoxState } from '../../state';

export function AssetViewBar() {
  const [searchOpen, setSearchOpen] = useRecoilState(searchBoxState);
  const [assetView, setAssetView] = useRecoilStateLoadable(asseViewState);
  const view = assetView.state === 'hasValue' ? assetView.contents : 'list';
  const updateView = (view: string) => {
    setAssetView(view);
  };
  return (
    <Toolbar>
      <IconButton
        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
        aria-label={`tottle between list and tile view`}
        onClick={() =>
          updateView(assetView.contents == 'tile' ? 'list' : 'tile')
        }
      >
        {view === 'list' ? <GridViewIcon /> : <ViewListIcon />}
      </IconButton>
      <IconButton
        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
        aria-label={`show search box`}
        onClick={() => setSearchOpen(!searchOpen)}
      >
        <SearchIcon />
      </IconButton>
    </Toolbar>
  );
}
