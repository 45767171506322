import * as React from 'react';
import { useRecoilValueLoadable } from 'recoil';

import { AssetStore } from '../../models';
import { downloadUrlStateFamily } from '../../state';

interface AssetPreviewProps {
  asset: AssetStore;
  use?: string;
  height?: string;
  width?: string;
}

export function AssetPreview(props: AssetPreviewProps) {
  const urlLoadable = useRecoilValueLoadable(
    downloadUrlStateFamily({ id: props.asset.id, use: props.use || 'proxy' }),
  );
  const height = props.height ? props.height : '400px';
  const width = props.width ? props.width : '400px';
  const url = urlLoadable.state === 'hasValue' ? urlLoadable.contents : null;
  return (
    // <div style={{ width: width, maxHeight: height }}>
    <div>
      {url ? (
        <img src={url} style={{ maxWidth: width, maxHeight: height }} />
      ) : (
        <div style={{ textAlign: 'center', marginTop: '50%' }}>
          No Preview Available
        </div>
      )}
    </div>
  );
}
