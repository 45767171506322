import * as React from 'react';
import { useRecoilValueLoadable } from 'recoil';

import { metadataFields } from '../../../state';
import { MetadataSearch } from './MetadataSearch';

export function AssetSearch() {
  const fields = useRecoilValueLoadable(metadataFields);
  return (
    <div>
      {fields.state === 'hasValue' ? (
        <MetadataSearch key="first" />
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}
